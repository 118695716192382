.title {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 40px;
}

.right-padding {
    padding-right: 50px;
}

.step-number {
    font-size: 20px;
    font-weight: bold;
}

@media only screen and (max-width: 600px) {
    .title {
        font-size: 30px;
        margin-bottom: 20px;
    }

    .step-number {
        font-size: 16px;
    }
}

.input-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.button-panel {
    display: flex;
    flex-direction: row;
    justify-content: center;
}