.persona-input-root {
    width: 100%;
}

.title {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 40px;
}

@media only screen and (max-width: 600px) {
    .title {
        font-size: 30px;
        margin-bottom: 20px;
    }
}

.right-padding {
    padding-right: 50px;
}

.selected-persona {
    padding: 10px;
    text-align: left;
    border: 2px solid white;
    border-radius: 20px;
    margin: 0 auto;
    min-height: 200px;
}

.chip-emoji {
    font-size: 18px;
    text-align: center;
    margin: auto 0;
    width: 25px;
    height: 25px;
}

.selection-panel {
    padding-top: 10px;
}

.selection-category {
    text-align: left;
}

.selection-category-desc {
    text-align: left;
    margin: 5px;
    font-size: 15px;
    font-weight: 600;
}

.custom-persona-input {
    text-align: left;
    margin-left: 5px;
}

.css-v4u5dn-MuiInputBase-root-MuiInput-root:before {
    border-bottom: 1px solid white !important;
}

.css-v4u5dn-MuiInputBase-root-MuiInput-root:after {
    border-bottom: 2px solid white !important;
}

.submit-persona-button {
    padding-top: 30px;
    padding-right: 20px;
    text-align: right;
}