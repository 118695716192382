.loading-box {
    height: 100%;
}

.padding-dummy {
    height: calc(50% - 95px);
    width: 100%;
}

.spinner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-words {
    margin-top: 16px;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
}

.icon-box {
    top: calc(50% - 50px) !important;
    left: calc(50% - 50px) !important;
    bottom: 0 !important;
    right: 0 !important;
    position: absolute !important;
}

.icon {
    width: 100px;
    animation: blink 3s;
    animation-iteration-count: infinite;
    /* filter: brightness(0) invert(1); */
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    10% {
        opacity: 0.8;
    }

    20% {
        opacity: 0.6;
    }

    30% {
        opacity: 0.4;
    }

    40% {
        opacity: 0.2;
    }

    50% {
        opacity: 0;
    }

    60% {
        opacity: 0.2;
    }

    70% {
        opacity: 0.4;
    }

    80% {
        opacity: 0.6;
    }

    90% {
        opacity: 0.8;
    }

    100% {
        opacity: 1;
    }
}