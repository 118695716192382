.app {
    height: 100%;
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 20px;
}