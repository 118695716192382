html,
body {
  margin: 0;
  font-family: 'Montserrat', 'Roboto', 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  font-weight: 500;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background: linear-gradient(to bottom right, #ff877c, #f9ccaf);
  background-size: cover;
}

body>#root {
  height: 100%;
}

@media only screen and (max-width: 600px) {
  body {
    font-family: 'Roboto', 'Segoe UI', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif !important;
  }
}