.product-display-root {
    height: 100%;
}

.product-display-title {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 40px;
    text-align: center;
}

@media only screen and (max-width: 1080px) {
    .product-display-title {
        font-size: 30px;
        margin-bottom: 5px;
    }
}

.product-carousel {
    width: 100%;
    text-align: -webkit-center;
    text-align: -moz-center;
}

.product-card-content {
    text-align: initial;
    display: flex;
    flex-flow: column;
    font-weight: normal;
    padding: 8px 12px 12px 12px;
    flex-grow: 1;
}

.product-card-name {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 24px;
    height: 48px;
    overflow: hidden;
}

.product-display {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
}

.product-list {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    width: 100%;
}

.product-card-price-seller {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.product-card-price {
    font-size: 16px;
    font-weight: 600;
    margin-right: 5px;
}

.product-card-seller {
    font-size: 15px;
    margin-left: 5px;
    overflow: hidden;
    flex-wrap: nowrap;
    flex: 1
}

.product-card-description {
    margin-top: 20px;
    font-size: 14px;
    text-align: start;
}

.review {
    margin-top: 5px !important;
}

.control-panel {
    margin-top: 30px !important;
}

@media only screen and (max-width: 1080px) {
    .control-panel {
        margin-top: 0px !important;
    }
}

.review-title {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}

.review-rating {
    text-align: center;
}

.review-footer {
    font-size: 20px;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 10px;
    text-align: center;
}