.feature-input {
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.step-number {
    font-size: 20px;
    font-weight: bold;
}

@media only screen and (max-width: 600px) {
    .step-number {
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .feature-input {
        margin: 0 10px;
    }
}

@media (min-width: 481px) and (max-width: 640px) {
    .feature-input {
        width: 80%;
        margin: 0 auto;
    }
}

@media (min-width: 641px) {
    .feature-input {
        width: 60%;
        margin: 0 auto;
    }
}